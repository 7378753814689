<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
      <v-dialog v-model="editdialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-layout wrap justify-center>
              <v-flex xs12>
                <v-col cols="12">
                  <v-text-field
                    v-model="referAFreind.title"
                    label="Refer Friends Title"
                    required
                    :rules="Rules"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="referAFreind.text"
                    label="Refer Friends Text"
                    :rules="Rules"
                    outlined
                  ></v-textarea>
                </v-col>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="(editdialog = false)"
          >
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="edit(editing)">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="form" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
              <v-card>
                <v-card-title class="elevation-1">
                  Invitation Category
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="category"
                  :search="search"
                  :items-per-page="count"
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:[`item._id`]="{ item }">
                    <v-icon small class="mr-2" @click="userinfo(item._id)">
                      mdi-eye
                    </v-icon>
                     <v-icon small class="mr-2"   @click="editCategoryDialog(item)">
                      mdi-pencil
                    </v-icon>
                  </template>
                </v-data-table>
                <div class="pt-2" v-if="pages > 1">
                  <v-pagination
                    :length="pages"
                    :total-visible="7"
                    v-model="currentPage"
                    color="#FF0000"
                  ></v-pagination>
                </div>
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      currentPage: 1,
      pages: 0,
      count: 20,
      totalData: 0,
      totalRows: 0,
      dialog: false,
      editdialog: false,
      editingitem: {},
      referAFreind:{
        title:"",
        text:"",
      },
      editing:"",
      name: "",
      caption: "",
      tags: "",
      tagArray: [],
      pageCount: 1,
      appLoading: false,
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,
      items: ["meta"],
      title: null,
      Rules: [(v) => !!v || "Required"],
      headers: [
        { text: "Name", value: "name" },
        { text: "Refer Friends Title", value: "referAFreind.title" },
        { text: "Refer Friends Text", value: "referAFreind.text" },
        { text: "Actions", value: "_id", sortable: false },
      ],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      category: [],
      data: [],
    };
  },
  watch: {
    currentPage() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/invitation/category/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.category = response.data.data;
          this.totalData = response.data.count;
          this.pages = response.data.pages;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
     edit(id) {
      this.addDialog = false;
      var data = {};
      data["referAFreind"] = this.referAFreind;
      data["id"] = id._id;
      axios({
        url: "/invitation/category/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
              this.getData();
            this.msg = "Edited!";
            this.showsnackbar = true;
            this.editdialog = false;
            this.title = null;
            this.text = null;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
      editCategoryDialog(item) {
      this.editing = item;
      this.editdialog = true;
      this.editId = item._id;
      this.referAFreind.title=this.editing.referAFreind.title
      this.referAFreind.text=this.editing.referAFreind.text
    },
     userinfo(uid) {
      this.$router.push("/invitationCategoryView?userId=" + uid);
    },
  },
};
</script>

<style scoped>
.table-striped:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
